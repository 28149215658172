import { ReadonlyURLSearchParams } from "next/navigation";

import { UTM_CACHE } from "@/constants/common";

const UTM_PARAMS = [
  "utmCampaign",
  "utmSource",
  "utmMedium",
  "utmContent",
  "utmTerm",
  "utm_campaign",
  "utm_source",
  "utm_medium",
  "utm_content",
  "utm_term",
] as const;

export const getUtmFromSearchParams = (
  s: ReadonlyURLSearchParams | URLSearchParams,
) => {
  const result: Partial<Record<(typeof UTM_PARAMS)[number], string>> = {};
  let hasUtmParams = false;
  for (let [k, v] of s.entries()) {
    const param = UTM_PARAMS.find((el) => el.toLowerCase() === k.toLowerCase());
    if (param) {
      result[param] = v;
      hasUtmParams = true;
    }
  }
  if (!hasUtmParams) return undefined;

  return result;
};

const trackEvent = (eventName: string, options: object = {}) => {
  if (!(globalThis as any) || !(globalThis as any).gtag) return;

  const utm = localStorage.getItem(UTM_CACHE);
  const optionsWithUtm = utm ? { ...JSON.parse(utm), ...options } : options;

  (globalThis as any).gtag("event", eventName, optionsWithUtm);
};

export const trackSessionStart = () => trackEvent("session_start");

export const trackSocialClick = (
  social: "discord" | "telegram" | "twitter" | "github" | "medium",
) => trackEvent("social_click", { social });

export const trackEarnClick = () => trackEvent("earn_click");
export const trackBannerClick = () => trackEvent("banner_click");

export const trackFooterLinkClick = (
  footerLink:
    | "quantstamp"
    | "risk dashboard"
    | "terms and conditions"
    | "privacy policy"
    | "documents",
) => trackEvent("footer_link_click", { footerLink });

export const trackConnectWalletClick = () => trackEvent("connect_wallet_click");
export const trackDisconnectWalletClick = () =>
  trackEvent("disconnect_wallet_click");

export const trackShareResultClick = () => trackEvent("share_result_click");
export const trackReferralLinkCopyClick = () =>
  trackEvent("referral_link_copy_click");
export const trackOpenRaffleResultModalClick = () =>
  trackEvent("open_raffle_result_modal_click");
export const trackRaffleBannerClick = () => trackEvent("raffle_banner_click");
export const trackRamsesBannerClick = () => trackEvent("ramses_banner_click");
export const trackJoinRaffleClick = () => trackEvent("join_raffle_click");
export const trackRaffleTradeClick = () => trackEvent("raffle_trade_click");
export const trackRaffleLendClick = () => trackEvent("raffle_lend_click");
export const trackRaffleLendBannerClick = () =>
  trackEvent("raffle_lend_banner_click");

export const trackLaunchAppClick = () => trackEvent("launch_app_click");
export const trackShowReportClick = () => trackEvent("show_report_click");
export const trackSubscribeNewsletterClick = () =>
  trackEvent("subscribe_newsletter_click");
export const trackSubscribeNewsletterSuccess = () =>
  trackEvent("subscribe_newsletter_success");
export const trackSubscribeNewsletterError = () =>
  trackEvent("subscribe_newsletter_error");

export const trackProvideLiquidityClick = () =>
  trackEvent("provide_liquidity_click");
export const trackAuditReportClick = () => trackEvent("audit_report_click");
export const trackFarmSparksClick = () => trackEvent("farm_sparks_click");
export const trackLearnMoreClick = () => trackEvent("learn_more_click");
export const trackRefLinkClick = () => trackEvent("ref_link_click");
